
//import ConstUtils from '@/utils/ConstUtils.js';

export default class CompanyUtils {

  static SortByName = function(a,b) {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  }
  
  static SortByCreatedDate = function(a, b) {
    if (a.createdDate > b.createdDate) {
      return -1;
    }
    if (a.createdDate < b.createdDate) {
      return 1;
    }
    return 0;
  }

  static SortByModifiedDate = function(a, b) {
    if (a.modifiedDate > b.modifiedDate) {
      return -1;
    }
    if (a.modifiedDate < b.modifiedDate) {
      return 1;
    }
    return 0;
  }
}